var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info != ""
    ? _c("div", { staticClass: "Receivingdetails" }, [
        _c("div", { staticClass: "auto-table-flex" }, [
          _c(
            "div",
            { staticClass: "ctns" },
            [
              _c("div", { staticClass: "divtitle" }, [
                _c("div", { staticClass: "titletab" }, [
                  _vm._v("接货单号：" + _vm._s(_vm.info.receive_order_no)),
                ]),
                _c("div", { staticClass: "div" }, [
                  _c("div", { staticClass: "its" }, [
                    _vm._v("自提点名称：" + _vm._s(_vm.info.delivery.name)),
                  ]),
                  _c("div", { staticClass: "its" }, [
                    _vm._v("联系电话：" + _vm._s(_vm.info.delivery.mobile)),
                  ]),
                  _c("div", { staticClass: "its" }, [
                    _vm._v("详细地址：" + _vm._s(_vm.info.delivery.address)),
                  ]),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.info.supplier_order_entruck_detail,
                    border: "",
                    "header-cell-style": { color: "#333333", fontSize: "16px" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      width: "100",
                      label: "序号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "商品名称", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(
                                      scope.row.supplier_order_detail.product
                                        .title
                                    )
                                  ) +
                                  "(" +
                                  _vm._s(
                                    _vm.$empty.empty(
                                      scope.row.supplier_order_detail.product
                                        .sku_code
                                    )
                                  ) +
                                  ") "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2120732558
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center", label: "等级" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(
                                      scope.row.supplier_order_detail.product
                                        .grade
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      469775235
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center", label: "毛重" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(
                                      scope.row.supplier_order_detail.product
                                        .gross_weight
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      616821107
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      align: "center",
                      label: "毛重合计",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tool.toDecimal2(
                                      (scope.row.supplier_order_detail.product
                                        .gross_weight *
                                        10000 *
                                        scope.row.origin_number *
                                        10000) /
                                        100000000
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      104178704
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      align: "center",
                      label: "装车数量",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.origin_number)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      626011998
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      align: "center",
                      label: "到货数量",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.review_number)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      17922896
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c("el-button", { on: { click: _vm.fanhui } }, [_vm._v("返回")]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.print } },
              [_vm._v("打印")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }