<template>
  <div class="Receivingdetails" v-if="info != ''">
    <div class="auto-table-flex">
      <div class="ctns">
        <div class="divtitle">
          <div class="titletab">接货单号：{{ info.receive_order_no }}</div>
          <div class="div">
            <div class="its">自提点名称：{{ info.delivery.name }}</div>
            <div class="its">联系电话：{{ info.delivery.mobile }}</div>
            <div class="its">详细地址：{{ info.delivery.address }}</div>
          </div>
        </div>
        <el-table
          :data="info.supplier_order_entruck_detail"
          border
          style="width: 100%"
          :header-cell-style="{ color: '#333333', fontSize: '16px' }"
        >
          <el-table-column type="index" align="center" width="100" label="序号">
          </el-table-column>
          <el-table-column prop="name" label="商品名称" align="center">
            <template slot-scope="scope">
              {{
                $empty.empty(scope.row.supplier_order_detail.product.title)
              }}({{
                $empty.empty(scope.row.supplier_order_detail.product.sku_code)
              }})
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="等级">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.supplier_order_detail.product.grade) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="毛重">
            <template slot-scope="scope">
              {{
                $empty.empty(
                  scope.row.supplier_order_detail.product.gross_weight
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="毛重合计">
            <template slot-scope="scope">
              {{
                tool.toDecimal2(
                  (scope.row.supplier_order_detail.product.gross_weight *
                    10000 *
                    scope.row.origin_number *
                    10000) /
                    100000000
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="装车数量">
            <template slot-scope="scope">
              <!-- {{ $empty.empty(scope.row.supplier_order_detail.product_number) }} -->
              {{ $empty.empty(scope.row.origin_number) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="到货数量">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.review_number) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="btn">
      <el-button @click="fanhui">返回</el-button>
      <el-button type="primary" @click="print">打印</el-button>
    </div>
  </div>
</template>
<script>
import { BASE } from "../../api/index";
export default {
  name: "Receivingdetails",
  components: {},
  data() {
    return {
      tableData: [],
      info: "",
    };
  },
  created() {
    this.hqinfo();
  },
  methods: {
    hqinfo() {
      this.$api.delivery
        .orderdetail({ id: this.$route.query.id })
        .then((res) => {
          console.log(res, "获取数据");
          this.info = res.data;
        });
    },
    print() {
      window.open(
        `${BASE.PRO3}/receive/order/detail/print` +
          `?id=${this.$route.query.id}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
      // this.$api.delivery
      //   .orderdetailprint({ id: this.$route.query.id })
      //   .then((res) => {
      //     console.log(res, "打印数据");
      //     window.open(
      //       res.data +
      //         `?token:${
      //           sessionStorage.getItem("token")
      //             ? sessionStorage.getItem("token")
      //             : ""
      //         }`
      //     );
      //   });
    },
    fanhui() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.Receivingdetails {
  height: 100%;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: auto;
    .ctns {
      padding: 23px 12px;
      box-sizing: border-box;
    }
  }
  .btn {
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 14px 0;

    box-sizing: border-box;
    justify-content: center;
  }

  .divtitle {
    .titletab {
      margin-bottom: 31px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .div {
      display: flex;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 24px;
      .its {
        flex: 1;
      }
    }
  }

  /**
  改变边框颜色
   */
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #666 !important;
  }
  /**
  改变表格内竖线颜色
   */
  /deep/ .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #666 !important;
  }
  /**
  改变表格内行线颜色
   */
  /deep/ .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #666 !important;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #666 !important;
  }

  /deep/ .el-table thead tr th {
    border-color: #666;
  }
  .rt {
    border-right: 1px solid #666666 !important;
  }
}
</style>
